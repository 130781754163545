import React from 'react'

const Notfound = () => {
  return (
    <div>
        <div className='error-div'>
            <img src='https://www.thesparksfoundationsingapore.org/images/logo_small.png' alt='logo' className='error-logo' />
        </div>
    </div>
  )
}

export default Notfound
