import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Notfound from './components/Notfound';
import AddVisitor from './components/AddVisitor';
import AllVisitors from './components/AllVisitors';
import VisitorInfo from './components/VisitorInfo';
import Upload from './components/Upload';




function App() {

  return (
    <BrowserRouter>
    <Navbar />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<AllVisitors />} />
        <Route path="/dashboard/addvisitor" element={<AddVisitor />} />
        <Route path="/dashboard/addvisitor/scan" element={<Upload />} />
        <Route path="/dashboard/visitor/:id" element={<VisitorInfo />} />
        
        <Route path="*" element={<Notfound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
