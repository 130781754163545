import React from 'react';
import './css/login.css';
import { Button, FormGroup, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Navbtns from './Navbtns';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../config.json'

const Login = () => {

  console.clear();

  //open and close for backdrop
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const submitLogin = async (data) => {
    try {
      setOpen(true);
      await axios.post(config.server+'/api/login', data) //http://us.hio01.botshard.com:10122/
        .then((res) => {
          if (res.data.isAuth === true) {
            localStorage.setItem('isAuth', res.data.isAuth);
            localStorage.setItem('name', res.data.name);
            localStorage.setItem('profilepic', res.data.profilepic);
            localStorage.setItem('sessionid', res.data.sessionid);
            console.log('Login Successful');
            navigate('/dashboard');
          }
          else {
            alert('Invalid Credentials');
          }
        })
        .catch((error) => {
          console.log(error);
          console.log('Login Failed');
        });
    }
    catch (error) {
      console.log(error);
      console('Error in Login');
    }
  }

  //if logged in never show login page
  React.useEffect(() => {
    if (localStorage.getItem('isAuth') === 'true') {
      navigate('/dashboard');
      console.log('Already Logged In');
    }
  }, [navigate]);

  const isAuth = localStorage.getItem('isAuth');


  return (
    (isAuth === 'true') ? null :
      <div>
        <Navbtns />
        <form onSubmit={handleSubmit(submitLogin)}>
          <FormGroup className='login-form'>
            <h1 className='login-title'>Login</h1>  <br />
            <TextField id="email" type='email' name='email' {...register('email')} label="Email" variant="filled" className='textfield' required /> <br />
            <TextField id="password" type='password' name='password' {...register('password')} label="Password" variant="filled" className='textfield' required /> <br /><br />
            <Button variant="outlined" className='login-button' type='submit' style={{ fontWeight: "bold" }} >LogIn</Button>
          </FormGroup>
        </form>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </div>
  )
}

export default Login
