import React from 'react'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Avatar, Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';
import './css/navbar.css'
import { Logout } from '@mui/icons-material';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../config.json'

const Navbtns = () => {

    const navigate = useNavigate();

    //open and close for backdrop
    const [dropopen, setdropOpen] = React.useState(false);

    //toggle button theme
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                        '#fff',
                    )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'light' ? '#003892' : '#001e3c',
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            borderRadius: 20 / 2,
        },
    }));



    //set dark mode
    const setDarkMode = () => {
        document.querySelector("body").setAttribute("data-theme", "dark");
        localStorage.setItem("theme", "dark");
    };

    //set light mode
    const setLightMode = () => {
        document.querySelector("body").setAttribute("data-theme", "light");
        localStorage.setItem("theme", "light");
    };

    //check theme
    const selectedTheme = localStorage.getItem("theme");
    if (selectedTheme === "dark") {
        setDarkMode();
    } else {
        setLightMode();
    }

    //toggle theme
    const toggleTheme = e => {
        if (e.target.checked) {
            setDarkMode();
        } else {
            setLightMode();
        }
    };


    //navbar dashboard menu control
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //logged in admin info
    const loggedInadminName = localStorage.getItem("name");
    const loggedInadminpfp = localStorage.getItem("profilepic");


    //logout function
    const hashedsessionid = localStorage.getItem("sessionid");
    const logoutfn = () => {
        setdropOpen(true);
        axios.post(config.server+'/api/logout',{ hashedsessionid : hashedsessionid })
            .then((res) => {
                if (res.data === 'Logged out') {
                    localStorage.removeItem("isAuth");
                    localStorage.removeItem("name");
                    localStorage.removeItem("profilepic");
                    localStorage.removeItem("sessionid");
                    setdropOpen(false);
                    navigate('/');
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }


    //getting params from url
    const {id} = useParams();

    // nav buttons change according to page
    const navbtns = () => {
        if (window.location.pathname === "/") {
            const navdiv = (<div className="theme-toggle-btn">
                <FormControlLabel control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked={selectedTheme === "dark"} onChange={toggleTheme} />} />
            </div>);
            return navdiv;
        }
        else if (window.location.pathname === "/dashboard" || window.location.pathname === "/dashboard/allvisitors" || window.location.pathname === "/dashboard/addvisitor" || window.location.pathname === "/dashboard/visitor/"+id || window.location.pathname === "/dashboard/addvisitor/scan")
         {
            const navdiv = (<div><div className="theme-toggle-btn-dash">
                <FormControlLabel control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked={selectedTheme === "dark"} onChange={toggleTheme} />} /></div>
                <div className='accountmenu'>
                    <React.Fragment>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', }}>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar className='avatar-bg' alt={loggedInadminName} src={loggedInadminpfp} sx={{ width: 40, height: 40 }} />
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                            <MenuItem disabled>
                                <Avatar className='avatar-bg' alt={loggedInadminName} src={loggedInadminpfp} sx={{ width: 40, height: 40 }} /><b>{loggedInadminName}</b>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logoutfn}>
                                <Logout fontSize="small" /> &nbsp; Logout
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                </div>
            </div>);
            return navdiv;
        }
    }


    return (
        <div>
            {navbtns()}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={dropopen}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Navbtns
