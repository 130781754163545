import React from 'react'
import Navbtns from './Navbtns'
import Sidebar from './Sidebar'
import config from '../config.json'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import './css/allvisitors.css'

const AllVisitors = () => {

  const navigate = useNavigate();

  // if not logged in never show dashboard page navigate to login page and show an alert message once after 500ms delay
  useEffect(() => {
    if (localStorage.getItem('isAuth') !== 'true') {
      navigate('/');
    }
  }, [navigate]);


  const [data, setData] = useState([]);

  // Get the data from API
  useEffect(() => {
    axios.post(config.server + '/api/getvisitors')
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
      }).catch((err) => {
        console.log(err);
      })
  }, []);

  //search visitors
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  // Filter the data based on the search query
  const SearchVisitors = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = query.trim() === '' ? data : data.filter((item) => {
      return item.name.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredData(filtered);
  };


  return (
    <div>
      <div className='fake-nav' />
      <Navbtns />
      <Sidebar />
      <div className='table-container'>
        <Table className='table'>
          <TableHead className='table-head'>
            <TableRow className='table-row'>
              <TableCell className='table-cell' sx={{width:"200px",fontWeight:"bold"}}>Name</TableCell>
              <TableCell className='table-cell' sx={{width:"200px",fontWeight:"bold"}}>Phone</TableCell>
              <TableCell className='table-cell' sx={{width:"200px",fontWeight:"bold"}}>Email</TableCell>
              <TableCell className='table-cell' sx={{width:"200px",fontWeight:"bold"}}>City</TableCell>
              <TableCell className='table-cell' ><input type='search' className='visitor-search' placeholder={"🔍 Search Visitors"} value={searchQuery} onChange={(e) => {SearchVisitors(e)}} results/></TableCell>
            </TableRow> 
          </TableHead>
          <TableBody className='table-body'>
          {filteredData.map((item) => (
                <TableRow className='table-row'>
                <TableCell className='table-cell-body'>{item.name}</TableCell>
                <TableCell className='table-cell-body'>{item.phone}</TableCell>
                <TableCell className='table-cell-body'>{item.email}</TableCell>
                <TableCell className='table-cell-body'>{item.city}</TableCell>
                <TableCell className='table-cell-body'><Link to={'/dashboard/visitor/'+item._id}><Button variant='outlined' className='viewvisitor-btn'>View</Button></Link></TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </div>

    </div>
  )
}

export default AllVisitors
