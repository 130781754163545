import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbtns from './Navbtns';
import Sidebar from './Sidebar';
import './css/visitorinfo.css';
import axios from 'axios';
import config from '../config.json';
import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material';

const VisitorInfo = () => {

  const navigate = useNavigate();

  // if not logged in never show dashboard page navigate to login page and show an alert message once after 500ms delay
  useEffect(() => {
    if (localStorage.getItem('isAuth') !== 'true') {
      navigate('/');
    }
  }, [navigate]);


  //get visitor id from url params
  const id = useParams();

  //get visitor information from database 
  const [visitorInfo, setVisitorInfo] = useState([]);
  React.useEffect(() => {
    axios.post(config.server + '/api/getvisitorsbyid', id)
      .then((res) => {
        setVisitorInfo(res.data);
        console.log(res.data.name + " Fetched Successfully");
        if (res.data.gender === "Male" && res.data.pfp === null) {
          res.data.pfp = 'https://i.imgur.com/CPDoSAs.png'
        }
        else if (res.data.gender === "Female" && res.data.pfp === null) {
          res.data.pfp = 'https://i.imgur.com/tOe19Mp.png'
        }
      }).catch((err) => {
        console.log(err);
      })
  }, [id]);


  //delete a visitor from the database
  const deleteVisitor = () => {
    const passfordel = prompt("This action is irreversible. Please enter your password to continue.");
    const admin = localStorage.getItem('name');
    axios.post(config.server + '/api/deletevisitor', { passfordel, admin , id })
      .then((res) => {
        if (res.data === "Success") {
          alert("Visitor Deleted Successfully");
          navigate('/dashboard');
        }
        else if (res.data === "Incorrect Password") {
          alert("Incorrect Password. Visitor Not Deleted");
        }
        else {
          alert("Something went wrong");
        }
      }
      ).catch((err) => {
        console.log(err);
      }
      )
  }

  return (
    <div>
      <div className='fake-nav' />
      <Navbtns />
      <Sidebar />

      <Table class="profile-table">
        <TableBody>
          <TableRow align='center'>
            <TableCell align='center' colSpan={9}>
              <img src={visitorInfo.pfp} alt='profile' className='profile-pic' /><br />
              <h2 className='visitor-name'>{visitorInfo.name}</h2>
              <h3 className='visitor-email'>{visitorInfo.email}</h3>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='profile-table-cell'><b>Phone</b></TableCell>
            <TableCell className='profile-table-cell' sx={{ borderRight: "1px solid rgba(143, 143, 143, 0.226)" }}>{visitorInfo.phone}</TableCell>
            <TableCell className='profile-table-cell'><b>Gender</b></TableCell>
            <TableCell className='profile-table-cell' sx={{ borderRight: "1px solid rgba(143, 143, 143, 0.226)" }}>{visitorInfo.gender}</TableCell>
            <TableCell className='profile-table-cell'><b>City</b></TableCell>
            <TableCell className='profile-table-cell'>{visitorInfo.city}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='profile-table-cell'><b>Address</b></TableCell>
            <TableCell className='profile-table-cell' sx={{ borderRight: "1px solid rgba(143, 143, 143, 0.226)" }}>{visitorInfo.address}</TableCell>
            <TableCell className='profile-table-cell'><b>City</b></TableCell>
            <TableCell className='profile-table-cell' sx={{ borderRight: "1px solid rgba(143, 143, 143, 0.226)" }}>{visitorInfo.city}</TableCell>
            <TableCell className='profile-table-cell'><b>Purpose Of Visit</b></TableCell>
            <TableCell className='profile-table-cell'>{visitorInfo.purpose}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='profile-table-cell'><b>Last Check In</b></TableCell>
            <TableCell className='profile-table-cell' sx={{ borderRight: "1px solid rgba(143, 143, 143, 0.226)" }}>{visitorInfo.dateofvisit + " " + visitorInfo.timeofvisit}</TableCell>
            <TableCell className='profile-table-cell'><b>Last Check Out</b></TableCell>
            <TableCell className='profile-table-cell' sx={{ borderRight: "1px solid rgba(143, 143, 143, 0.226)" }}>{visitorInfo.checkout}</TableCell>
            <TableCell className='profile-table-cell'><b>Managed Admin</b></TableCell>
            <TableCell className='profile-table-cell'>{localStorage.getItem('name')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderRight: "1px solid rgba(143, 143, 143, 0.226)" }} colSpan={2} align='center'><Button variant='outlined' className='profile-table-btn'>Check In</Button></TableCell>
            <TableCell className='profile-table-btn' sx={{ borderRight: "1px solid rgba(143, 143, 143, 0.226)" }} colSpan={2} align='center'><Button variant='outlined' className='profile-table-btn'>dfgdfg</Button></TableCell>
            <TableCell className='profile-table-btn' colSpan={2} align='center'><Button className='profile-table-btn' variant='outlined' color='error' onClick={() => { deleteVisitor() }}>Delete Visitor</Button></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default VisitorInfo
