import * as React from 'react';
import './css/footer.css';
import config from '../config.json';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <div class='footer'>
         <div class="footer-icons">
            {/* YouTube Icon */}
            <div class="icon-bg yt">
              <a href={config.footeryoutubeurl} title='Youtube' target="_blank" rel='noreferrer'><YouTubeIcon className='footer-icons' /></a>
            </div>&nbsp;

            {/* Facebook Icon */}
            <div class="icon-bg fb">
              <a href={config.footerfacebookurl} title='Facebook' target="_blank" rel='noreferrer'><FacebookIcon className='footer-icons' /></a>
            </div>&nbsp;

            {/* Twitter Icon */}
            <div class="icon-bg tw">
              <a href={config.footertwitterurl} title='Twitter' target="_blank" rel='noreferrer'><TwitterIcon className='footer-icons' /></a>
            </div>&nbsp;

            {/* Instagram Icon */}
            <div class="icon-bg ig">
              <a href={config.footerinstagramurl} title='Instagram' target="_blank" rel='noreferrer'><InstagramIcon className='footer-icons' /></a>
            </div>
         </div>
         <div class="footer-text">© {(new Date().getFullYear())} <a className='footer_url' href={config.footer_url} target='_blank' rel='noreferrer'>{config.footer_url_text}</a> {config.footer_non_url_text}</div>
    </div>
  );
};

export default Footer;