import React from 'react'
import Clock from 'react-live-clock';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './css/dashboard.css'

const Sidebar = () => {
  return (
    <div>
      <div className='sidebar'>
          <Link to="/dashboard" style={{ textDecoration: "none" }}><div className='sidebar-dash-btn'><span className='menu-text'>Dashboard</span></div></Link>
          <Link to="/dashboard/addvisitor" style={{ textDecoration: "none" }}><div className='sidebar-dash-btn'><span className='menu-text'>Add New Visitor</span></div></Link>
          <Link to="/dashboard/addvisitor/scan" style={{ textDecoration: "none" }}><div className='sidebar-dash-btn'><span className='menu-text'>Scan ID</span></div></Link>
          <Link to="/dashboard" style={{ textDecoration: "none" }}><div className='sidebar-dash-btn'><span className='menu-text'>Add Revisitor</span></div></Link>

          <div className='sidebar-time'>{moment().format("MMMM DD YYYY")}&nbsp;|&nbsp;<Clock format={'h:mm:ss A'} ticking={true} timezone={'Asia/Kolkata'} /></div>
        </div>
    </div>
  )
}

export default Sidebar
