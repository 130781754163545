import React, { createRef, useState } from 'react';
import './css/upload.css';
import { useNavigate } from 'react-router-dom';
import Navbtns from './Navbtns';
import config from '../config.json'
import Sidebar from './Sidebar';


const Upload = () => {

    const navigate = useNavigate();

    // if not logged in never show dashboard page navigate to login page and show an alert message once after 500ms delay
    React.useEffect(() => {
        if (localStorage.getItem('isAuth') !== 'true') {
            navigate('/');
        }
    }, [navigate]);

    const isAuth = localStorage.getItem('isAuth');


    const [filename, setFilename] = useState('No file chosen');

    //accept only png, jpg, jpeg files
    const filetypechecker = () => {
        const file = document.querySelector('input[type=file]').files[0];
        const fileName = file.name;
        setFilename(fileName);
        const fileExtension = file.name.split('.').pop();
        if (fileExtension === 'jpeg') {
            document.getElementById('submit_btn').hidden = false;
        } else {
            alert('Please upload a jpeg file');
            document.getElementById('submit_btn').hidden = true;
            setFilename('No file chosen');
            window.location.reload();
        }
    }

    //upload file to server
    const fileInput = createRef();

    const uploadFile = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.set('aadhaar', fileInput.current.files[0]);
        try {
            const response = await fetch(config.server+'/uploadfile', {
                method: 'POST',
                body: formData
            });
            const parsedResponse = await response.json();
            console.log(parsedResponse);
            if (response.ok) {
                alert("File uploaded successfully");
            }
        }
        catch (err) {
            console.error(err.message);
        }
    }

    return (
        (isAuth === 'true') ?
        <div>
        <Navbtns />
        <div className='fake-nav' />
        <Sidebar />
        <div class="container">
            <h1>ID Uploader</h1>
            <form class="upload-form" id='fileuploadform' onSubmit={uploadFile}>
                <input type="file" id="file-input" name="aadhaar" ref={fileInput} class="file-input" accept='image/jpeg' onChange={() => { filetypechecker(this) }} />
                <label for="file-input" class="file-label">Choose File</label>
                <span class="file-name" >{filename}</span>
                <button type="submit" id='submit_btn' class="upload-btn" hidden>Upload</button>
            </form>
        </div></div> : null
    )
}

export default Upload
