import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import './css/addvisitor.css';
import Navbtns from './Navbtns';
import Sidebar from './Sidebar';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import config from '../config.json';

const AddVisitor = () => {

    const navigate = useNavigate();

    // if not logged in never show dashboard page navigate to login page and show an alert message once after 500ms delay
    useEffect(() => {
        if (localStorage.getItem('isAuth') !== 'true') {
            navigate('/');
        }
    }, [navigate]);


    const { register, handleSubmit } = useForm();
    const GotoDashboard = () => {
        navigate('/dashboard');
    }

    //submit application
    const submitApplication = (data) => {
        
        axios.post(config.server + '/api/addvisitor', data)
            .then((res) => {
                if (res.data === "Visitor already exists") {
                    alert("Visitor already exists");
                }
                else if (res.data === "Date of birth cannot be in future") {
                    alert("Date of birth cannot be in future");
                }
                else if (res.data === "Visitor added successfully") {
                    alert(data.name + " has been added as a visitor.");
                    navigate('/dashboard');
                }
            }).catch((err) => {
                console.log(err);
                alert("Visitor could not be added");
            })
    }


    //phone number input
    const [phone, setPhone] = React.useState("+91")
    const phonehandleChange = (newPhone) => {
        matchIsValidTel(newPhone);
        setPhone(newPhone);
    }

    //date of birth input
    const [dobtype, setDobtype] = React.useState('text');
    const handleDobtypeChange = (event) => {
        setDobtype('date');
        console.log('date type');

    };


    return (<div>
        <div className='fake-nav' />
        <Navbtns /><Sidebar />
        <form className='visitor-form'>
            <h1 className='visitor-form-heading'>New Visitor</h1>
            <div className='element-group'>
                <TextField className='visitor-form-text-field' type='text' name="name" id="name" {...register('name')} label="Full Name" variant="filled" required />&nbsp;&nbsp;&nbsp;&nbsp;
                <TextField className='visitor-form-text-field' type='text' name="email" id="email" {...register('email')} label="Email" variant="filled" required />
            </div>
            <div className='element-group'>
                <TextField className='visitor-form-text-field' type={dobtype} name='dob' id='dob' {...register('dob')} variant='filled' label="Date Of Birth" onFocus={handleDobtypeChange} required />&nbsp;&nbsp;&nbsp;&nbsp;
                <FormControl className='visitor-form-text-field' sx={{ backgroundColor: "dark" }} variant='filled' required>
                    <InputLabel id="gender">Gender</InputLabel>
                    <Select
                        labelId="gender"
                        id="gender"
                        label="Gender" className='visitor-form-text-field'
                        {...register('gender')}>
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='element-group'>
                <MuiTelInput label="Phone Number" className='visitor-form-text-field-number' defaultCountry="in" {...register('phone')} variant="filled" value={phone} onChange={phonehandleChange} required /> &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField className='visitor-form-text-field' type='text' name="address" id="address" {...register('address')} label="Address" variant="filled" required />
            </div>
            <div className='element-group'>
                <TextField className='visitor-form-text-field' type='text' name="city" id="city" {...register('city')} label="City" variant="filled" required />&nbsp;&nbsp;&nbsp;&nbsp;
                <TextField className='visitor-form-text-field' type='text' name="purpose" id="purpose" {...register('purpose')} label="Purpose Of Visit" variant="filled" required />
                <input type='text' name="host" id="host" {...register('host')} value={localStorage.getItem('name')} hidden={true} />
            </div>
            <div className='element-group'>
                <Button className='visitor-add-button' variant="outlined" style={{ fontWeight: "bold" }} onClick={handleSubmit(GotoDashboard)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                <Button type='submit' className='visitor-add-button' variant="outlined" style={{ fontWeight: "bold" }} onClick={handleSubmit(submitApplication)}>Check In</Button>
            </div>
        </form></div>)
}

export default AddVisitor



