import { Link } from "react-router-dom";
import "./css/navbar.css";
import * as React from "react";
import config from "../config.json";


const Navbar = () => {

    return (
        <div>
            <div class="navbar">
                <div class="navlogo">
                    <Link to="/" style={{ color: "white" }}>
                        <img class="navlogo-img" src={config.navbarlogo} alt="Navlogo" />
                        <h6 class="navtext">{config.navbartitle}</h6>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Navbar;